import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import { Link } from 'gatsby';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
export const _frontmatter = {
  "title": "MQTT Alarmserver as a Lightswitch",
  "path": "/Frequently_Asked_Question/MQTT_Alarmserver_PIR_Switch/",
  "dateChanged": "2021-01-19",
  "author": "Mike Polinowski",
  "excerpt": "I want to use the cameras PIR sensor to switch on motion detection area 1 and couple the area with the PIR sensor. Now, only when both - the PIR and Area1 - are triggered, I want to switch on an external light in front of my house.",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='FAQs - MQTT Alarmserver as a Lightswitch' dateChanged='2021-01-19' author='Mike Polinowski' tag='INSTAR IP Camera' description='I want to use the cameras PIR sensor to switch on motion detection area 1 and couple the area with the PIR sensor. Now, only when both - the PIR and Area1 - are triggered, I want to switch on an external light in front of my house.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/MQTT_Alarmserver_PIR_Switch/' locationFR='/fr/Frequently_Asked_Question/MQTT_Alarmserver_PIR_Switch/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "mqtt-alarmserver-as-a-lightswitch",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#mqtt-alarmserver-as-a-lightswitch",
        "aria-label": "mqtt alarmserver as a lightswitch permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MQTT Alarmserver as a Lightswitch`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I want to use the cameras PIR sensor to switch on `}<Link to="/Web_User_Interface/1080p_Series/Alarm/Areas/" mdxType="Link">{`motion detection area 1`}</Link>{` and `}<Link to="/Web_User_Interface/1080p_Series/Alarm/Actions/" mdxType="Link">{`link the area with the PIR sensor`}</Link>{`. Now, only when both - the PIR and Area1 - are triggered, I want to switch on an external light in front of my house.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><strong parentName="p">{`A`}</strong>{`: Here is an example how this problem can be solved in `}<Link to="/Advanced_User/INSTAR_MQTT_Broker/Node-RED/" mdxType="Link">{`Node-RED`}</Link>{` using the `}<Link to="/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver/" mdxType="Link">{`MQTT Alarmserver`}</Link>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/en/bf5742fd62e94c49c0ab358becd88b1e/instar_alarmserver.gif",
        "alt": "INSTAR MQTT Alarmserver as Lightswitch"
      }}></img></p>
    <p>{`The PIR is triggered updating the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`alarm/triggered`}</code>{` MQTT topic with a payload value of `}<strong parentName="p">{`5`}</strong>{`. I am now using this trigger to switch the area1 `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`alarm/area1/enable`}</code>{` on and link it to the PIR `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`alarm/actions/linkareas`}</code>{`. They are switched off again once the alarm server falls back to idle with a payload value of `}<strong parentName="p">{`0`}</strong>{` - this happens after 5 seconds, which might be a bit too fast for your use case. You can replace this with an personal timer.`}</p>
    <p>{`Once area1 is active and linked the next alarm trigger `}<Link to="/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver/" mdxType="Link">{`will have the value 7`}</Link>{`) (PIR + Area1 triggered). This trigger will then be used to switch on a light that is also connected to Node-RED - this implementation is not part of the example flow.`}</p>
    <h2 {...{
      "id": "node-red-flow-export",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#node-red-flow-export",
        "aria-label": "node red flow export permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED Flow Export`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"52d8de5c.878f4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"tab"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"label"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"INSTAR Alarmserver"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"disabled"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"info"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"a071baf8.e4db18"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt in"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"52d8de5c.878f4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"status/alarm/triggered"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"topic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"cameras/117/status/alarm/triggered"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"qos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"datatype"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"auto"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"broker"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"7553a41d.22b8bc"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`107`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`76`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"27e5d697.4c750a"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"a076e8c3.98bbd8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"a076e8c3.98bbd8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"debug"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"52d8de5c.878f4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Alarmserver"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"active"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tosidebar"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"console"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tostatus"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"complete"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"payload"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"targetType"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"msg"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"statusVal"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"statusType"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"auto"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`297`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`25`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"27e5d697.4c750a"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"switch"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"52d8de5c.878f4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0 / 5 / 7"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"property"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"payload"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"propertyType"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"msg"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"rules"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"t"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"eq"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"v"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"val\\":\\"0\\"}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"vt"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"str"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"t"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"eq"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"v"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"val\\":\\"5\\"}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"vt"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"str"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"t"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"eq"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"v"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"val\\":\\"7\\"}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"vt"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"str"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"checkall"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"true"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"repair"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"outputs"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`287`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`76`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"472c5b6d.4e03d4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"a4e93b6b.595ed8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"d1f7562c.1d9bf8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"7bddd11d.f8ee2"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"b09b8001.82bf7"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"a4e93b6b.595ed8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"change"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"52d8de5c.878f4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"rules"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"t"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"set"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"p"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"payload"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"pt"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"msg"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"to"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"val\\":\\"0\\"}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tot"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"str"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"action"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"property"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"from"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"to"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"reg"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`462`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`81`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"3778f880.f4f898"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"b635569a.0f6418"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt out"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"52d8de5c.878f4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"alarm/actions/linkareas"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"topic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"cameras/117/alarm/actions/linkareas"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"qos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"retain"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"broker"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"7553a41d.22b8bc"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`669`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`68`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"472c5b6d.4e03d4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"change"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"52d8de5c.878f4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"off"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"rules"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"t"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"set"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"p"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"payload"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"pt"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"msg"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"to"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"val\\":\\"off\\"}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tot"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"str"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"action"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"property"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"from"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"to"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"reg"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`462`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`43`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"b635569a.0f6418"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"b09b8001.82bf7"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"change"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"52d8de5c.878f4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"fiat lux"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"rules"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"t"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"set"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"p"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"payload"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"pt"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"msg"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"to"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"fiat lux"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tot"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"str"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"action"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"property"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"from"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"to"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"reg"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`459`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`198`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"eb589db.cc37e6"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"eb589db.cc37e6"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"debug"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"52d8de5c.878f4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Light Switch"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"active"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tosidebar"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"console"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tostatus"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"complete"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"payload"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"targetType"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"msg"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"statusVal"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"statusType"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"auto"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`599`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`198`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"7bddd11d.f8ee2"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"change"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"52d8de5c.878f4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"rules"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"t"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"set"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"p"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"payload"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"pt"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"msg"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"to"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"val\\":\\"1\\"}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tot"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"str"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"action"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"property"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"from"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"to"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"reg"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`462`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`156`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"3778f880.f4f898"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"d1f7562c.1d9bf8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"change"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"52d8de5c.878f4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"on"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"rules"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"t"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"set"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"p"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"payload"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"pt"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"msg"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"to"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"val\\":\\"on\\"}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tot"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"str"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"action"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"property"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"from"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"to"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"reg"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`462`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`118`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"b635569a.0f6418"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"3778f880.f4f898"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt out"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"52d8de5c.878f4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"alarm/area1/enable"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"topic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"cameras/117/alarm/area1/enable"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"qos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"retain"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"broker"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"7553a41d.22b8bc"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`650`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`115`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"7553a41d.22b8bc"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt-broker"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"192.168.2.117"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"broker"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"192.168.2.117"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"port"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"8883"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tls"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"c411fab7.e16228"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"clientid"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"usetls"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"compatmode"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"keepalive"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"60"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"cleansession"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"birthTopic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"birthQos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"birthPayload"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"closeTopic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"closeQos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"closePayload"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"willTopic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"willQos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"willPayload"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"c411fab7.e16228"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"tls-config"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"cert"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"key"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"ca"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"certname"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt_client-new.crt"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"keyname"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"caname"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"servername"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"verifyservercert"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      